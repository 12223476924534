import React from "react";

import logIn from "../../actions/logIn";
import FirebaseAuth from "../misc/FirebaseAuth";
import Error from "../misc/Error";
// import Profile from "./Profile";
// import Subscription from "./Subscription";
import { Page } from "../../styles/layout";
import { Link, NavLink, Redirect, Route, Switch } from "react-router-dom";
import Listening from "./Listening";
import Reading from "./Reading";
import Speaking from "./Speaking";
import Writing from "./Writing";
import Class from "./Class";

const IELTS = ({ match }) => (
  <Page>
    <ul className="nav nav-tabs">
      <li className="nav-item">
        <NavLink
          activeClassName="active"
          className="nav-link"
          aria-current="page"
          to={`${match.url}/listening`}
        >
          Listening
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          activeClassName="active"
          className="nav-link"
          aria-current="page"
          to={`${match.url}/speaking`}
        >
          Speaking
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          activeClassName="active"
          className="nav-link"
          aria-current="page"
          to={`${match.url}/reading`}
        >
          Reading
        </NavLink>
      </li>
      <li className="nav-item">
        <NavLink
          activeClassName="active"
          className="nav-link "
          tabIndex="-1"
          aria-disabled="true"
          to={`${match.url}/writing`}
        >
          Writing
        </NavLink>
      </li>
    </ul>
    <div>
      <Switch>
        <Route exact path={`${match.path}/listening`} component={Listening} />
        <Route exact path={`${match.path}/speaking`} component={Speaking} />
        <Route exact path={`${match.path}/reading`} component={Reading} />
        <Route exact path={`${match.path}/writing`} component={Writing} />
        <Redirect from="/ielts" to="/ielts/listening" />
      </Switch>
    </div>
  </Page>
);

export default IELTS;
{
  /* <Route path="/ielts/listening/edit/:id" component={Class} />
<Route path="/ielts/listening/edit/:id/practice" component={Class} /> */
}
