import React, { useEffect } from "react";
import addCollege from "../../actions/addCollege";
import { useHistory } from "react-router-dom";
import Error from "../misc/Error";
export default function AddCollege({ match }) {
  const history = useHistory();

  const { country, course } = match.params;
  const onSave = (event) => {
    event.preventDefault();

    const {
      university,
      location,
      foundation,
      academicQualification,
      interview,
      moi,
      inner,
      paymentSchedule,
      minRequiredDocuments,
      scholarship,
      applicationMode,
      boardAcceptance,
      turnAroundPeriod,
      yearGap,
      stepProcedure,
      remarks,
      currency,
      // fee,
      duration,
      feePG,
      feeUG,
      why,
      expenses,
    } = event.target.elements;

    const values = {
      country_id: country,
      course_id: course,
      university: university.value,
      location: location.value,
      foundation: foundation.value,
      academicQualification: academicQualification.value,
      interview: interview.value,
      moi: moi.value,
      inner: inner.value,
      paymentSchedule: paymentSchedule.value,
      minRequiredDocuments: minRequiredDocuments.value,
      scholarship: scholarship.value,
      applicationMode: applicationMode.value,
      boardAcceptance: boardAcceptance.value,
      turnAroundPeriod: turnAroundPeriod.value,
      yearGap: yearGap.value,
      stepProcedure: stepProcedure.value,
      remarks: remarks.value,
      currency: currency.value,
      // fee: fee.value,
      duration: duration.value,
      feePG: feePG.value,
      feeUG: feeUG.value,
      why: why.value,
      expenses: expenses.value,
    };

    addCollege(values);
    history.goBack();
  };
  return (
    <form className="mt-2" onSubmit={onSave}>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="university">University</label>
          <input
            type="text"
            className="form-control"
            name="university"
            name="university"
            placeholder="University"
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="location">Location</label>
          <input
            type="text"
            className="form-control"
            name="location"
            placeholder="Location"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="foundation">Foundation / Pre Masters</label>
          <textarea
            className="form-control"
            name="foundation"
            placeholder="FOUNDATION / PRE MASTERS"
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="academicQualification">Academic Qualification</label>
          <textarea
            className="form-control"
            name="academicQualification"
            placeholder="Academic Qualification"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="interview">Interview</label>
          <input type="text" className="form-control" name="interview" />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="moi">MOI</label>
          <select name="moi" className="form-control">
            <option>Choose...</option>
            <option>YES</option>
            <option>NO</option>
          </select>
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="inner">Inner / Outer</label>
          <input type="text" className="form-control" name="inner" />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="paymentSchedule">Payment Schedule</label>
          <textarea
            className="form-control"
            name="paymentSchedule"
            placeholder="Payment Schedule"
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="minRequiredDocuments">Min Required Documents</label>
          <textarea
            className="form-control"
            name="minRequiredDocuments"
            placeholder="Min Required Documents"
          />
        </div>
      </div>
      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="scholarship">Scholarship</label>
          <input
            type="text"
            className="form-control"
            name="scholarship"
            placeholder="Scholarship"
          />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="applicationMode">Application Mode</label>
          <input
            type="text"
            className="form-control"
            name="applicationMode"
            placeholder="Application Mode"
          />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-6">
          <label htmlFor="boardAcceptance">
            Punjab , Gujarat & Hariyana Board Acceptance
          </label>
          <input type="text" className="form-control" name="boardAcceptance" />
        </div>
        <div className="form-group col-md-6">
          <label htmlFor="turnAroundPeriod">Offer Turn Around Period</label>
          <input type="text" className="form-control" name="turnAroundPeriod" />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="yearGap">Educational Year Gap (COMMON)</label>
          <textarea className="form-control" name="yearGap" />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="stepProcedure">Step by Step Procedure</label>
          <textarea className="form-control" name="stepProcedure" />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="remarks">Remarks</label>
          <textarea className="form-control" name="remarks" />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-3">
          <label htmlFor="duration">Course Duration</label>
          <input type="text" className="form-control" name="duration" />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="currency">Currency</label>
          <select name="currency" className="form-control" defaultValue="$">
            <option value="$">Dollar</option>
            <option value="£">Pound Sterling</option>
            <option value="€">Euro</option>
            <option value="Fr">Swiss Franc</option>
            <option value="₽">Russian Ruble</option>
          </select>
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="fee">Fee for UG</label>
          <input type="text" className="form-control" name="feeUG" />
        </div>
        <div className="form-group col-md-2">
          <label htmlFor="fee">Fee for PG</label>
          <input type="text" className="form-control" name="feePG" />
        </div>
      </div>

      <div className="form-row">
        <div className="form-group col-md-4">
          <label htmlFor="why">Why this Country?</label>
          <textarea className="form-control" name="why" />
        </div>
        <div className="form-group col-md-4">
          <label htmlFor="expenses">Expenses</label>
          <textarea className="form-control" name="expenses" />
        </div>
      </div>

      <button type="submit" className="btn btn-primary">
        Save
      </button>
    </form>
  );
}
