import React from "react";
import { FirestoreCollection } from "react-firestore";

import Error from "../misc/Error";
import FireflySubscription from "../misc/FireflySubscription";
import { HeaderLink, InternalLink } from "../../styles/links";
import { Page } from "../../styles/layout";
import FirebaseAuth from "../misc/FirebaseAuth";
import { Redirect, Route, Switch } from "react-router-dom";
import Layout from "../layout/Layout";

import Account from "../account/Account";
import Dashboard from "../dashboard/Dashboard";
import Country from "../country/Country";
import Course from "../course/Course";
import College from "../college/College";
import AddCollege from "../college/AddCollege";
import EditCollege from "../college/EditCollege";
import IELTS from "../IELTS/IELTS";
import Class from "../IELTS/Class";

const Home = ({ match, history }) => (
  <Layout>
    <FirebaseAuth>
      {({ isLoading, error, auth }) => {
        if (isLoading) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div className="spinner-grow" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          );
        }
        if (error) {
          return "⚠️ login error";
        }
        if (auth) {
          return (
            <main role="main" className="container">
              <Page>
                <Switch>
                  <Route exact path="/" component={Dashboard} />

                  <Route path="/ielts/edit/:module/:id" component={Class} />
                  <Route path="/ielts" component={IELTS} />
                  {/* <Route path="/ielts/listening/edit/:id/practice" component={Class} /> */}
                  <Route path="/account" component={Account} />
                  <Route path="/search/country" component={Country} />
                  <Route path="/search/course" component={Course} />
                  <Route
                    path="/search/university/edit/:id"
                    component={EditCollege}
                  />
                  <Route
                    path="/search/university/:country/:course"
                    component={AddCollege}
                  />
                  <Route path="/search/university" component={College} />
                  {/* <Redirect from="/ielts" to="/ielts/listening" /> */}

                  <Route component={Error} />
                </Switch>
              </Page>
            </main>
          );
        } else {
          return <Redirect to="/login" />;
        }
      }}
    </FirebaseAuth>
  </Layout>
);

export default Home;
{
  /* <FirebaseAuth>
    {({ isLoading, error, auth }) => {
      console.log("Auth", auth);
      if (isLoading) {
        return "...";
      }
      if (error) {
        return "⚠️ login error";
      }
      if (auth) {
        return (
          <Page>
            <InternalLink to="/new">Add College</InternalLink>
            <button type="button" classNameNameName="btn btn-primary">
              Primary
            </button>
            <hr />
            <FirestoreCollection
              path={"country"}
              // sort="_likeCount:desc"
            >
              {({ error, isLoading, data }) => {
                console.log("data :", data);
                if (error) {
                  return <Error error={error} />;
                }

                if (isLoading) {
                  return <p>loading...</p>;
                }

                if (data.length === 0) {
                  return <p>No posts yet!</p>;
                }

                return (
                  <select>
                    {data.map((post) => (
                      <option key={post.id}>{post.country_name}</option>
                    ))}
                  </select>
                );
              }}
            </FirestoreCollection>

            <hr />

            {/* For paid subscribers only *
            <FireflySubscription>
              {({ isLoading, error, subscription }) => {
                if (error) {
                  return <Error error={error} />;
                }

                if (isLoading) {
                  return <p>loading...</p>;
                }

                if (!subscription) {
                  return (
                    <div>
                      <p>Only paid subscribers can see what goes here</p>
                      <InternalLink to={`/account`}>Subscribe now</InternalLink>
                    </div>
                  );
                }

                return (
                  <div>
                    <p>Super-fancy subscription-only features go here!</p>
                  </div>
                );
              }}
            </FireflySubscription>
          </Page>
        );
      } else {
        return <Redirect to="/login" />;
        {
         
        }
      }
    }}
  </FirebaseAuth> */
}
