import React from "react";
import { Route } from "react-router-dom";

import logOut from "../../actions/logOut";

const Profile = ({ auth }) => (
  <div className="card mx-auto" style={{ width: "18rem" }}>
    <Route
      render={({ history }) => (
        <div className="card-body">
          <img
            src={auth.photoURL}
            alt={auth.displayName}
            width="100"
            height="100"
            className="img-thumbnail"
          />
          <p>
            <strong>{auth.displayName}</strong>
          </p>
          <p>{auth.email}</p>
          <button
            className="btn btn-outline-danger"
            onClick={() => logOut().then(() => history.push(`/`))}
          >
            Log out
          </button>
        </div>
      )}
    />
    {/* <div className="card-body">
      <h5 className="card-title">Card title</h5>
      <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
      <p className="card-text">
        Some quick example text to build on the card title and make up the bulk
        of the card's content.
      </p>
      <a href="#" className="card-link">
        Card link
      </a>
      <a href="#" className="card-link">
        Another link
      </a>
    </div> */}
  </div>
);

export default Profile;
