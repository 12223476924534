import React, { useEffect, useState } from "react";
import Firebase from "firebase/app";

import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom";
import Table from "../components/Table";
import { Modal, Button } from "react-bootstrap";
import { Editor, EditorState } from "draft-js";
import "draft-js/dist/Draft.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const AddClass = () => {
  const { id, module } = useParams();
  const [loading, setLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [progress, setProgress] = useState(0);
  // const [notes, setNotes] = useState([{ notes: "" }]);

  const [classes, setClasses] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const [note, setNote] = useState("");

  const handleClose = () => {
    if (loading) {
      return false;
    }
    setShowAddModal(false);
    setShowDeleteModal(false);
    setSelectedId("");
    setSelectedClass("");
    setNote("");
    setSelectedFile("");
  };

  const addClass = (module, id) => {
    setProgress(0);
    setLoading(true);

    console.log("selectedFile :", selectedFile);
    console.log("selectedId :", selectedId);

    if (!selectedFile && selectedId) {
      Firebase.firestore()
        .collection("ielts_modules")
        .doc(module.toString())
        .collection("chapters")
        .doc(id.toString())
        .collection("classes")
        .doc(selectedId)
        .update({
          note,
        })
        .then((docRef) => {
          console.log("Document updated ");
          setLoading(false);
          handleClose();
          // console.log(docRef);
          fetchClasses(module, id);
        })
        .catch((error) => {
          handleClose();
          console.log("Error upating document: ", error);
          setLoading(false);
        });

      return false;
    }

    const storageRef = Firebase.storage().ref();

    const audioRef = storageRef.child(`${module}/${selectedFile.name}`);
    // console.log("module", typeof module, module);
    // console.log("id", typeof id, id);

    const uploadTask = audioRef.put(selectedFile);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        setProgress(progress);
        switch (snapshot.state) {
          case Firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case Firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          console.log("File available at", downloadURL);
          if (selectedId) {
            // selectedFile
            Firebase.firestore()
              .collection("ielts_modules")
              .doc(module.toString())
              .collection("chapters")
              .doc(id.toString())
              .collection("classes")
              .doc(selectedId)
              .update({
                file_name: selectedFile.name,
                audio_file: downloadURL,
                note,
              })
              .then((docRef) => {
                console.log("Document updated ");
                setLoading(false);
                handleClose();
                // console.log(docRef);
                fetchClasses(module, id);
              })
              .catch((error) => {
                handleClose();
                console.log("Error upating document: ", error);
                setLoading(false);
              });
            return false;
          }

          Firebase.firestore()
            .collection("ielts_modules")
            .doc(module.toString())
            .collection("chapters")
            .doc(id.toString())
            .collection("classes")
            .add({
              file_name: selectedFile.name,
              audio_file: downloadURL,
              note,
              created_at: Firebase.firestore.Timestamp.now(),
            })
            .then((docRef) => {
              console.log("Document written with ID: ", docRef.id);
              setLoading(false);
              handleClose();
              // console.log(docRef);
              fetchClasses(module, id);
            })
            .catch((error) => {
              handleClose();
              console.log("Error adding document: ", error);
              setLoading(false);
            });
        });
      }
    );
  };

  // On file select (from the pop up)
  const onFileChange = (event) => {
    // Update the state
    setSelectedFile(event.target.files[0]);
  };

  const fetchClasses = (module, id) => {
    console.log("calling fetch class", module, id);
    setLoading(true);
    Firebase.firestore()
      .collection("ielts_modules")
      .doc(module)
      .collection("chapters")
      .doc(id)
      .collection("classes")
      .orderBy("created_at")
      .get()
      .then((querySnapshot) => {
        setLoading(false);
        if (!querySnapshot.empty) {
          let classes = [];
          querySnapshot.forEach((documentSnapshot) => {
            classes = [
              ...classes,
              { ...documentSnapshot.data(), id: documentSnapshot.id },
            ];
          });
          // console.log(classes.length);
          setClasses(classes);
        } else {
          setClasses([]);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const deleteClass = async (module, id) => {
    setLoading(true);
    // Create a reference to the file to delete
    const storageRef = Firebase.storage().ref();
    const audioRef = storageRef.child(`${module}/${selectedClass}`);

    try {
      await audioRef.delete();
    } catch (error) {}

    Firebase.firestore()
      .collection("ielts_modules")
      .doc(module)
      .collection("chapters")
      .doc(id)
      .collection("classes")
      .doc(selectedId)
      .delete()
      .then((docRef) => {
        console.log("document deleted successfully", module, id);
        fetchClasses(module, id);
      })
      .catch((e) => {
        console.log("---", e);
        setLoading(false);
      });
  };

  useEffect(() => {
    // const { id, module } = useParams();
    fetchClasses(module, id);
  }, [module, id]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        Cell: ({ cell }) => <>{cell.row.index + 1}</>,
      },
      {
        Header: "File",
        accessor: "file_name",
      },
      {
        Header: "Audio",
        accessor: "audio_file",
        Cell: ({ cell: { row: original } }) => {
          return (
            <audio controls>
              <source src={original.original.audio_file} />
            </audio>
          );
        },
      },
      {
        Header: "Has Note",
        accessor: "notes",
        Cell: ({ cell: { row: original } }) => {
          return <span>{original.original.note ? "Yes" : "No"}</span>;
        },
      },
      {
        Header: "Download Audio",
        accessor: "",
        Cell: ({ cell: { row: original } }) => {
          return (
            <a href={original.original.audio_file} download target="_blank">
              Download
            </a>
          );
        },
      },
      {
        Header: " ",
        columns: [
          {
            Header: " ",
            accessor: "",
            Cell: ({ cell: { row: original } }) => {
              return (
                <button
                  type="button"
                  className="btn btn-info btn-sm mr-2"
                  onClick={() => {
                    setSelectedId(original.original.id);
                    setNote(original.original.note);
                    setShowAddModal(true);
                    setSelectedClass(original.original.file_name);
                  }}
                >
                  Edit
                </button>
              );
            },
          },
          {
            Header: "  ",
            accessor: "",
            Cell: ({ cell: { row: original } }) => {
              return (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    // alert(original.original.id);
                    // handleShow();
                    setSelectedId(original.original.id);
                    setSelectedClass(original.original.file_name);
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </button>
              );
            },
          },
        ],
      },
    ],
    []
  );
  const classes_data = React.useMemo(() => classes, [classes]);

  return (
    <div className="container">
      <h5 className="mt-2">Classes</h5>
      <Table
        columns={columns}
        data={classes_data}
        onAddChapter={() => {
          setShowAddModal(true);
          setNote("");
        }}
      />
      <Modal size="lg" show={showAddModal} onHide={handleClose}>
        <Modal.Header closeButton>
          {loading ? (
            <h5>Saving, Please wait... {Math.floor(progress)}%</h5>
          ) : (
            <h5>
              {selectedId ? "Update an existing class" : "Add a new class"}
            </h5>
          )}
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <form>
              <div className="form-group">
                <label htmlFor="audioFile">
                  Audio File{" "}
                  <span className="text-primary">
                    {selectedId ? `- (${selectedClass})` : ""}
                  </span>
                </label>
                <input
                  disabled={loading}
                  onChange={onFileChange}
                  type="file"
                  accept="audio/*"
                  className="form-control-file"
                  id="audioFile"
                />
              </div>
            </form>

            <ReactQuill theme="snow" value={note} onChange={setNote} />

            {/* {notes.map((x, i) => {
              return (
                <div className="box" key={i}>
                  <textarea
                    rows={3}
                    className="form-control"
                    name="notes"
                    placeholder="Add Note"
                    value={x.notes}
                    onChange={(e) => handleInputChange(e, i)}
                  />

                  <div className="btn-box m-2 ml-0 mb-4">
                    {notes.length !== 1 && (
                      <button
                        disabled={loading}
                        className="btn btn-danger mr-2"
                        onClick={() => handleRemoveClick(i)}
                      >
                        -
                      </button>
                    )}
                    {notes.length - 1 === i && (
                      <button
                        disabled={loading}
                        className="btn btn-primary"
                        onClick={handleAddClick}
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
              );
            })} */}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            disabled={loading}
            variant="primary"
            onClick={() => {
              if (!selectedId && !selectedFile) {
                alert("Please select a file");
                return false;
              }
              addClass(module, id);
              // handleClose();

              //   deleteUniversity(selectedId);
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete
          <span className="text-danger"> {selectedClass}</span>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteClass(module, id);
              handleClose();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AddClass;
