import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import { logInWithEmailAndPassword } from "../../actions/logIn";
import FirebaseAuth from "../misc/FirebaseAuth";
import { ReactComponent as YourSvg } from "./../../assets/logo.svg";
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [hasError, setHasError] = useState(null);
  return (
    <FirebaseAuth>
      {({ isLoading, error, auth }) => {
        if (isLoading) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div className="spinner-grow" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          );
        }
        if (error) {
          return "⚠️ login error";
        }
        if (auth) {
          return <Redirect to="/" />;
        } else {
          return (
            <div className="d-flex vh-100">
              <div className="container text-center m-auto">
                <form className="form-signin">
                  <YourSvg height={30} className="mb-5" />

                  <label for="inputEmail" className="sr-only">
                    Email address
                  </label>
                  <input
                    type="email"
                    id="inputEmail"
                    className="form-control"
                    placeholder="Email address"
                    required
                    autofocus
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <label for="inputPassword" className="sr-only">
                    Password
                  </label>
                  <input
                    type="password"
                    id="inputPassword"
                    className="form-control"
                    placeholder="Password"
                    required
                    onChange={(event) => setPassword(event.target.value)}
                  />

                  <button
                    className="btn btn-lg btn-primary btn-block"
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      if (!email || !password) {
                        return false;
                      }
                      const re =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                      if (!re.test(String(email).toLowerCase())) {
                        return false;
                      }

                      logInWithEmailAndPassword(email, password)
                        .then((result) => {
                          console.log(
                            `logged in as ${result.user.displayName}`
                          );
                        })
                        .catch((error) => {
                          setHasError(error);
                          setTimeout(() => {
                            setHasError("");
                          }, 5000);
                          console.error("could not sign in s", error);
                        });
                    }}
                  >
                    Sign in
                  </button>
                  <p className="mt-5 mb-3 text-muted">&copy; 2021</p>
                </form>

                {/* <div className="d-flex h-100">
                <div className="m-auto">
                  <form>
                    <div className="form-group">
                      <h2 className="login-header">Login</h2>
                    </div>
                    <div className="form-group">
                      <input
                        required
                        type="text"
                        name="email"
                        placeholder="Email"
                        className="username-input"
                        onChange={(event) => setEmail(event.target.value)}
                      />
                    </div>
                    <div className="form-group">
                      <input
                        required
                        type="password"
                        name="password"
                        placeholder="Password"
                        className="password-input"
                        onChange={(event) => setPassword(event.target.value)}
                      />
                    </div>

                    {hasError ? (
                      <div className="alert alert-danger" role="alert">
                        Something went wrong!, Please try again!
                      </div>
                    ) : null}
                    <div className="form-group">
                      <button
                        className="btn btn-primary"
                        onClick={(e) => {
                          e.preventDefault();
                          if (!email || !password) {
                            return false;
                          }
                          const re =
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                          if (!re.test(String(email).toLowerCase())) {
                            return false;
                          }

                          logInWithEmailAndPassword(email, password)
                            .then((result) => {
                              console.log(
                                `logged in as ${result.user.displayName}`
                              );
                            })
                            .catch((error) => {
                              setHasError(error);
                              setTimeout(() => {
                                setHasError("");
                              }, 5000);
                              console.error("could not sign in s", error);
                            });
                        }}
                      >
                        Login
                      </button>
                    </div>
                  </form>
                </div>
              </div> */}
              </div>
            </div>
          );
        }
      }}
    </FirebaseAuth>
  );
}
