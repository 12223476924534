import Firebase from "firebase/app";
import ReactGA from "react-ga";
import slugify from "slugify";

import { prepareDocForCreate } from "./helpers/firestoreHelpers";

const addCourse = (course_name, country_id) => {
  ReactGA.event({
    category: "Post",
    action: "Create post",
  });

  //   values.slug = slugify(values.title, { lower: true });
  //   values._likeCount = 0;
  const values = {
    course_name,
    country_id,
  };
  prepareDocForCreate(values);
  return Firebase.firestore()
    .collection("course")
    .add(values)
    .then(() => values)
    .catch((error) => {
      console.log(error);
      alert(`Whoops, couldn't create the post: ${error.message}`);
    });
};

export default addCourse;
