import React, { useState, useEffect } from "react";
import { FirestoreCollection } from "react-firestore";
import addCountry from "../../actions/addCountry";
import addCourse from "../../actions/addCourse";
import deleteCountry from "../../actions/deleteCountry";
import deleteCourse from "../../actions/deleteCourse";
import Error from "../misc/Error";
import { useHistory } from "react-router-dom";
import deleteUniversity from "../../actions/deleteUniversity";
import { Modal, Button } from "react-bootstrap";
import List from "./List";
export default function College() {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courseName, setCourseName] = useState("");
  const [selectedId, setSelectedId] = useState("");
  const history = useHistory();

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    const _selectedCountry = localStorage.getItem("selectedCountry");
    const _selectedCourse = localStorage.getItem("selectedCourse");
    setSelectedCountry(_selectedCountry || "");
    setSelectedCourse(_selectedCourse || "");
  }, []);

  return (
    <div>
      <FirestoreCollection
        path={"university"}
        filter={[
          ["country_id", "==", selectedCountry],
          ["course_id", "==", selectedCourse],
        ]}
        sort="createdOn"
      >
        {({ error, isLoading, data }) => {
          {
            /* console.log("data :", data); */
          }
          if (error) {
            return <Error error={error} />;
          }

          if (isLoading) {
            return (
              <div className="d-flex justify-content-center align-items-center ">
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            );
          }
          console.log("selectedCountry :", selectedCountry);
          console.log("selectedCourse :", selectedCourse);
          return (
            <div>
              <div className="row mt-2">
                <div className="col-6">
                  <FirestoreCollection
                    path={"country"}
                    // sort="_likeCount:desc"
                  >
                    {({ error, isLoading, data }) => {
                      return (
                        <div className="row mb-3">
                          <div className="col-8">
                            <select
                              className="form-select "
                              aria-label="Default select example"
                              onChange={(e) => {
                                setSelectedCountry(e.target.value);
                                localStorage.setItem(
                                  "selectedCountry",
                                  e.target.value
                                );
                                localStorage.setItem("selectedCourse", "");

                                setSelectedCourse("");
                              }}
                              value={selectedCountry}
                            >
                              <option value={""}>Select Country</option>
                              {data.map((country, index) => (
                                <option key={country.id} value={country.id}>
                                  {country.country_name}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      );
                    }}
                  </FirestoreCollection>

                  <div className="page-header mt-2">
                    <FirestoreCollection
                      path={"course"}
                      filter={["country_id", "==", selectedCountry]}
                      // sort="_likeCount:desc"
                    >
                      {({ error, isLoading, data }) => {
                        return (
                          <div className="row mb-5">
                            <div className="col-8">
                              <select
                                className="form-select "
                                aria-label="Default select example"
                                onChange={(e) => {
                                  setSelectedCourse(e.target.value);
                                  localStorage.setItem(
                                    "selectedCourse",
                                    e.target.value
                                  );
                                }}
                                value={selectedCourse}
                              >
                                <option value={""}>Select Course</option>
                                {data.map((course, index) => (
                                  <option key={course.id} value={course.id}>
                                    {course.course_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        );
                      }}
                    </FirestoreCollection>
                  </div>
                </div>
                <div className="col-6">
                  {selectedCountry && selectedCourse ? (
                    <div className="float-right">
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={() =>
                          history.push(
                            `/search/university/${selectedCountry}/${selectedCourse}`
                          )
                        }
                      >
                        Add University
                      </button>
                    </div>
                  ) : null}
                </div>
                {/* <div className="clearfix"></div> */}
              </div>
              {data.length === 0 ? (
                <h5 className="card-title">No Universities found</h5>
              ) : null}
              {data.length !== 0 ? (
                <>
                  <List data={data} />
                  {/* <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">University</th>
                        <th scope="col"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((university, index) => (
                        <tr key={university.id}>
                          <th scope="row">{index + 1}</th>
                          <td>{university.university}</td>
                          <td>
                            <button
                              type="button"
                              className="btn btn-info btn-sm mr-2"
                              onClick={() => {
                                //   deleteUniversity(university.id);
                                history.push(
                                  `/search/university/edit/${university.id}`
                                );
                              }}
                            >
                              Edit
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => {
                                handleShow();
                                setSelectedId(university.id);
                              }}
                            >
                              Delete
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table> */}
                </>
              ) : null}
            </div>
          );
        }}
      </FirestoreCollection>

      <div
        className="modal fade"
        id="addCountry"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                ></label>
                <input
                  onChange={(event) => setCourseName(event.target.value)}
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Course Name"
                  required
                />
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                disabled={!courseName && !selectedCountry}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  addCourse(courseName, selectedCountry);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        {/* <FirestoreCollection
          path={"country"}
          // sort="_likeCount:desc"
        >
          {({ error, isLoading, data }) => {
            return (
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header border-0">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setSelectedCountry(e.target.value)}
                      >
                        {data.map((country, index) => (
                          <option key={country.id} value={country.id}>
                            {country.country_name}
                          </option>
                        ))}
                      </select>

                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      ></label>
                      <input
                        onChange={(event) => setCourseName(event.target.value)}
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Course Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer border-0">
                    <button
                      disabled={!courseName && !selectedCountry}
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      onClick={() => {
                        alert(selectedCountry);
                        // addCourse(courseName, selectedCountry);
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        </FirestoreCollection> */}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteUniversity(selectedId);
              handleClose();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

{
  /* <div
              className="modal fade"
              id="addCountry"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <FirestoreCollection
                path={"country"}
                // sort="_likeCount:desc"
              >
                {({ error, isLoading, data }) => {
                  console.log(error, isLoading, data);
                  return (
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header border-0">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="mb-3">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              {data.map((country, index) => (
                                <option value={country.id}>
                                  {country.country_name}
                                </option>
                              ))}
                            </select>

                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            ></label>
                            <input
                              onChange={(event) =>
                                setCountryName(event.target.value)
                              }
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Country Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="modal-footer border-0">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={() => {
                              if (!countryName) {
                                return false;
                              }
                              addCountry(countryName);
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </FirestoreCollection>
            </div> */
}
