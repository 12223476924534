import React, { useState } from "react";
import { FirestoreCollection } from "react-firestore";
import addCountry from "../../actions/addCountry";
import addCourse from "../../actions/addCourse";
import deleteCountry from "../../actions/deleteCountry";
import deleteCourse from "../../actions/deleteCourse";
import Error from "../misc/Error";
import { Modal, Button } from "react-bootstrap";

export default function Course() {
  const [selectedCountry, setSelectedCountry] = useState("");
  const [courseName, setCourseName] = useState("");
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <FirestoreCollection
        path={"course"}
        filter={["country_id", "==", selectedCountry]}
        // sort="_likeCount:desc"
      >
        {({ error, isLoading, data }) => {
          {
            /* console.log("data :", data); */
          }
          if (error) {
            return <Error error={error} />;
          }

          if (isLoading) {
            return (
              <div className="d-flex justify-content-center align-items-center ">
                <div className="spinner-grow" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            );
          }
          console.log("selectedCountry :", selectedCountry);
          return (
            <div>
              <div className="page-header mt-2">
                <FirestoreCollection
                  path={"country"}
                  // sort="_likeCount:desc"
                >
                  {({ error, isLoading, data }) => {
                    return (
                      <div className="row mb-5">
                        <div className="col-4">
                          <select
                            className="form-select "
                            aria-label="Default select example"
                            onChange={(e) => setSelectedCountry(e.target.value)}
                            value={selectedCountry}
                          >
                            <option value={""}>Select Country</option>
                            {data.map((country, index) => (
                              <option key={country.id} value={country.id}>
                                {country.country_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    );
                  }}
                </FirestoreCollection>
                <div className="float-left">
                  {data.length === 0 ? (
                    <h5 className="card-title">No course found</h5>
                  ) : (
                    <h5 className="card-title">List of Course</h5>
                  )}
                </div>
                {selectedCountry ? (
                  <div className="float-right">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-toggle="modal"
                      data-target="#addCountry"
                      onClick={() => setCourseName("")}
                    >
                      Add Course
                    </button>
                  </div>
                ) : null}
                <div className="clearfix"></div>
              </div>

              {data.length !== 0 ? (
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Course</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((course, index) => (
                      <tr key={course.id}>
                        <th scope="row">{index + 1}</th>
                        <td>{course.course_name}</td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-danger btn-sm"
                            onClick={() => {
                              handleShow();
                              setSelectedId(course.id);
                            }}
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : null}
            </div>
          );
        }}
      </FirestoreCollection>

      <div
        className="modal fade"
        id="addCountry"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header border-0">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <label
                  htmlFor="exampleFormControlInput1"
                  className="form-label"
                ></label>
                <input
                  value={courseName}
                  onChange={(event) => setCourseName(event.target.value)}
                  type="text"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Course Name"
                  required
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      if (!courseName) {
                        return false;
                      }
                      addCourse(courseName, selectedCountry);
                      setCourseName("");
                    }
                  }}
                />
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                disabled={!courseName && !selectedCountry}
                type="button"
                className="btn btn-primary"
                data-dismiss="modal"
                onClick={() => {
                  addCourse(courseName, selectedCountry);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </div>
        {/* <FirestoreCollection
          path={"country"}
          // sort="_likeCount:desc"
        >
          {({ error, isLoading, data }) => {
            return (
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header border-0">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                        onChange={(e) => setSelectedCountry(e.target.value)}
                      >
                        {data.map((country, index) => (
                          <option key={country.id} value={country.id}>
                            {country.country_name}
                          </option>
                        ))}
                      </select>

                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      ></label>
                      <input
                        onChange={(event) => setCourseName(event.target.value)}
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Course Name"
                        required
                      />
                    </div>
                  </div>
                  <div className="modal-footer border-0">
                    <button
                      disabled={!courseName && !selectedCountry}
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      onClick={() => {
                        alert(selectedCountry);
                        // addCourse(courseName, selectedCountry);
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            );
          }}
        </FirestoreCollection> */}
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteCourse(selectedId);
              handleClose();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

{
  /* <div
              className="modal fade"
              id="addCountry"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <FirestoreCollection
                path={"country"}
                // sort="_likeCount:desc"
              >
                {({ error, isLoading, data }) => {
                  console.log(error, isLoading, data);
                  return (
                    <div className="modal-dialog" role="document">
                      <div className="modal-content">
                        <div className="modal-header border-0">
                          <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div className="modal-body">
                          <div className="mb-3">
                            <select
                              className="form-select"
                              aria-label="Default select example"
                            >
                              {data.map((country, index) => (
                                <option value={country.id}>
                                  {country.country_name}
                                </option>
                              ))}
                            </select>

                            <label
                              htmlFor="exampleFormControlInput1"
                              className="form-label"
                            ></label>
                            <input
                              onChange={(event) =>
                                setCountryName(event.target.value)
                              }
                              type="text"
                              className="form-control"
                              id="exampleFormControlInput1"
                              placeholder="Country Name"
                              required
                            />
                          </div>
                        </div>
                        <div className="modal-footer border-0">
                          <button
                            type="button"
                            className="btn btn-primary"
                            data-dismiss="modal"
                            onClick={() => {
                              if (!countryName) {
                                return false;
                              }
                              addCountry(countryName);
                            }}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </FirestoreCollection>
            </div> */
}
