import { injectGlobal } from "styled-components";

// https://www.styled-components.com/docs/api#injectglobal
injectGlobal`
  *:before,
  *:after,
  * {
    box-sizing: border-box;
    font-family: sans-serif;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  html,
  body {
    height: 100%;
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }

  html,
  body,
  #root {
    min-height: 100vh;
  }

  p {
    margin: 0 0 1rem;
  }

  hr {
    border: none;
    height: 1px;
    background: #eee;
    margin: 1rem 0;
  }
  .dropdown .nav-link {color:#fff; text-decoration: none;}
  .navbar-brand {color:#fff; text-decoration: none;}
  .navbar-brand:hover {color:#fff; text-decoration: none;}
  .dropdown-menu .dropdown-item {display: inline-block; width: 100%; padding: 10px 10px;}
 
  .center_div{
    margin: 0 auto;
    width:80% /* value of your choice which suits your alignment */
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: 0 auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
 
`;
