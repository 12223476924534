// this Layout component wraps every page with the app header on top
// check out App.js to see how it's used

import React from "react";

import logIn from "../../actions/logIn";
import FirebaseAuth from "../misc/FirebaseAuth";
import { HeaderFooterWrapper, Header, Footer } from "../../styles/layout";
import { HeaderLink } from "../../styles/links";
import { Link, NavLink } from "react-router-dom";

const Layout = ({ children }) => (
  <HeaderFooterWrapper>
    <Header>
      {/* <HeaderLink to="/">Letsvsoo</HeaderLink> */}

      <div style={{ float: "right" }}>
        <FirebaseAuth>
          {({ isLoading, error, auth }) => {
            if (isLoading) {
              return "...";
            }
            if (error) {
              return "⚠️ login error";
            }
            if (auth) {
              return (
                <nav
                  className="navbar navbar-expand-md fixed-top"
                  style={{ backgroundColor: "#32357C" }}
                >
                  {/* <a className="navbar-brand" href="">
                    Letsvoo
                  </a> */}
                  {/* <Link */}
                  <Link className="navbar-brand" to="/">
                    Letsvoo
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-toggle="collapse"
                    data-target="#navbarsExampleDefault"
                    aria-controls="navbarsExampleDefault"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon"></span>
                  </button>

                  <div
                    className="collapse navbar-collapse"
                    id="navbarsExampleDefault"
                  >
                    <ul className="navbar-nav mr-auto">
                      <li className="nav-item">
                        {/* <a className="nav-link" href="#">
                          IELTS
                        </a> */}
                        <NavLink
                          activeClassName="active"
                          className="nav-link text-white"
                          to="/ielts"
                        >
                          IELTS
                        </NavLink>
                      </li>
                      <li className="nav-item dropdown">
                        <a
                          className="nav-link dropdown-toggle"
                          href="#"
                          id="dropdown01"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Search
                        </a>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdown01"
                        >
                          <HeaderLink
                            className="dropdown-item"
                            to={`/search/country`}
                          >
                            Add Country
                          </HeaderLink>
                          <HeaderLink
                            className="dropdown-item"
                            to={`/search/course`}
                          >
                            Add Course
                          </HeaderLink>

                          <HeaderLink
                            onClick={() => {
                              localStorage.clear();
                            }}
                            className="dropdown-item"
                            to={`/search/university`}
                          >
                            Add University
                          </HeaderLink>
                        </div>
                      </li>
                    </ul>
                    <HeaderLink to={`/account`}>
                      <span role="img" aria-label="account">
                        👤
                      </span>
                    </HeaderLink>
                  </div>
                </nav>
              );
            } else {
              return null;
            }
          }}
        </FirebaseAuth>
      </div>
    </Header>

    {children}

    <Footer>© {new Date().getFullYear()}</Footer>
  </HeaderFooterWrapper>
);

export default Layout;
