// the main routes of our app are defined here using react-router
// https://reacttraining.com/react-router/web/example/basic

import React from "react";
import { Switch, Route } from "react-router-dom";

import PostNew from "./posts/PostNew";
import Search from "./search/Search";
import Account from "./account/Account";
import PostEdit from "./posts/PostEdit";
import Post from "./posts/Post";
import Error from "./misc/Error";
import Login from "./auth/Login";
import Layout from "./layout/Layout";
import Home from "./home/Home";

const Routes = () => (
  <Switch>
    <Route exact path="/login" component={Login} />
    <Route path="/" component={Home} />

    {/* <Route path="/new" component={PostNew} />
    <Route path="/search" component={Search} />
    <Route path="/account" component={Account} />
    <Route path="/:slug/edit" component={PostEdit} />
    <Route path="/:slug" component={Post} /> */}
    <Route component={Error} />
  </Switch>
);

export default Routes;
