import React, { useEffect, useState } from "react";
import Firebase from "firebase/app";
import { firestore } from "../../..";
import Table from "./components/Table";
import { useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
const Listening = () => {
  const history = useHistory();
  const [selectedId, setSelectedId] = useState(null);
  const [selectedChapter, setSelectedChapter] = useState(null);
  const [chapterName, setChapterName] = useState("");
  const [chapters, setChapters] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  const handleClose = () => {
    setShowDeleteModal(false);
    setShowAddModal(false);
    setChapterName("");
    setSelectedId("");
    setSelectedChapter("");
  };

  const addChapter = () => {
    setLoading(true);
    Firebase.firestore()
      .collection("ielts_modules")
      .doc("listening")
      .collection("chapters")
      .add({
        chapter_name: chapterName,
        create_at: Firebase.firestore.Timestamp.now(),
      })
      .then((docRef) => {
        fetchChapter();
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const deleteChapter = () => {
    setLoading(true);
    Firebase.firestore()
      .collection("ielts_modules")
      .doc("listening")
      .collection("chapters")
      .doc(selectedId)
      .delete()
      .then((docRef) => {
        fetchChapter();
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };

  const fetchChapter = () => {
    setLoading(true);
    Firebase.firestore()
      .collection("ielts_modules")
      .doc("listening")
      .collection("chapters")
      .orderBy("create_at")
      .get()
      .then((querySnapshot) => {
        setLoading(false);
        if (!querySnapshot.empty) {
          let chapters = [];
          querySnapshot.forEach((documentSnapshot) => {
            chapters = [
              ...chapters,
              { ...documentSnapshot.data(), id: documentSnapshot.id },
            ];
          });
          setChapters(chapters);
        } else {
          setChapters([]);
        }
      })
      .catch((e) => {
        console.log(e);
        setLoading(false);
      });
  };
  //   const handleShow = () => setShow(true);

  useEffect(() => {
    fetchChapter();
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        Cell: ({ cell }) => <>{cell.row.index + 1}</>,
      },
      {
        Header: "Chapter",
        accessor: "chapter_name",
      },
      {
        Header: " ",
        columns: [
          {
            Header: " ",
            accessor: "",
            Cell: ({ cell: { row: original } }) => {
              return (
                <button
                  type="button"
                  className="btn btn-info btn-sm mr-2"
                  onClick={() => {
                    history.push({
                      pathname: `/ielts/edit/listening/${original.original.id}`,
                      state: {
                        chapter: original.original.chapter_name,
                        module: "Listening",
                      },
                    });
                  }}
                >
                  Edit
                </button>
              );
            },
          },
          {
            Header: "  ",
            accessor: "",
            Cell: ({ cell: { row: original } }) => {
              return (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    // alert(original.original.id);
                    // handleShow();
                    setSelectedId(original.original.id);
                    setSelectedChapter(original.original.chapter_name);
                    setShowDeleteModal(true);
                  }}
                >
                  Delete
                </button>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const chapter_data = React.useMemo(() => chapters, [chapters]);
  return (
    <div className="container">
      <h5 className="mt-2">Chapters</h5>
      <Table
        columns={columns}
        data={chapter_data}
        onAddChapter={() => setShowAddModal(true)}
      />

      <Modal show={showAddModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add a new chapter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mb-3">
            <label
              htmlFor="exampleFormControlInput1"
              className="form-label"
            ></label>
            <input
              onChange={(event) => setChapterName(event.target.value)}
              type="text"
              className="form-control"
              id="exampleFormControlInput1"
              placeholder="Chapter Name"
              required
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              if (chapterName) {
                addChapter();
              }
              //   deleteUniversity(selectedId);
              handleClose();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Warning!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete
          <span className="text-danger"> {selectedChapter}</span>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteChapter();
              handleClose();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Listening;
