import React, { useEffect, useState } from "react";
import Firebase from "firebase/app";
import { Page } from "../../../styles/layout";
import {
  Link,
  NavLink,
  Redirect,
  Route,
  Switch,
  useLocation,
  useParams,
  useHistory,
} from "react-router-dom";
import AddClass from "./AddClass";
import AddPractice from "./AddPractice";

const Class = ({ match }) => {
  const [moduleName, setModuleName] = useState("");
  const [chapter, setChapter] = useState("");
  const { id, module } = useParams();
  const history = useHistory();

  useEffect(() => {
    setModuleName(module);
    Firebase.firestore()
      .collection("ielts_modules")
      .doc(module)
      .collection("chapters")
      .doc(id)
      .get()
      .then((doc) => {
        console.log(`${doc.id} => ${doc.data()}`);
        setChapter({ id: doc.id, ...doc.data() });
      })
      .catch((e) => {
        console.log(e);
      });
  }, [module]);

  return (
    <Page>
      <div className="mb-2">
        <Link to={`/ielts`}>
          {moduleName.charAt(0).toUpperCase() + moduleName.slice(1)}
        </Link>
        <span> /</span> {chapter.chapter_name}
      </div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <NavLink
            activeClassName="active"
            className="nav-link"
            aria-current="page"
            to={`${match.url}`}
          >
            Classes
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink
            activeClassName="active"
            className="nav-link"
            aria-current="page"
            to={`${match.url}/practice`}
          >
            Practices
          </NavLink>
        </li>
      </ul>
      <div>
        <Switch>
          <Route exact path={`${match.path}`} component={AddClass} />
          <Route
            exact
            path={`${match.path}/practice`}
            component={AddPractice}
          />

          {/* <Redirect from={pathname} to={`${pathname}/classes`} /> */}
        </Switch>
      </div>
    </Page>
  );
};
export default Class;
