import Firebase from "firebase/app";
import ReactGA from "react-ga";
import slugify from "slugify";

import { prepareDocForCreate } from "./helpers/firestoreHelpers";

const addCollege = (values) => {
  ReactGA.event({
    category: "Post",
    action: "Create post",
  });

  //   values.slug = slugify(values.title, { lower: true });
  //   values._likeCount = 0;

  prepareDocForCreate(values);
  return Firebase.firestore()
    .collection("university")
    .add(values)
    .then(() => values)
    .catch((error) => {
      console.log(error);
      alert(`Whoops, couldn't create the post: ${error.message}`);
    });
};

export default addCollege;
