import React, { useState } from "react";
import Table from "./components/Table";
import { useHistory } from "react-router-dom";
import deleteUniversity from "../../actions/deleteUniversity";
import { Modal, Button } from "react-bootstrap";

function List({ data }) {
  const history = useHistory();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [selectedId, setSelectedId] = useState("");
  const [selectedUniversity, setSelectedUniversity] = useState("");

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "",
        Cell: ({ cell }) => <>{cell.row.index + 1}</>,
      },
      {
        Header: "University",
        accessor: "university",
      },
      {
        Header: "Location",
        accessor: "location",
      },
      {
        Header: "Fee",
        columns: [
          {
            Header: "Fee for UG",
            accessor: "feeUG",
          },
          {
            Header: "Fee for PG",
            accessor: "feePG",
          },
        ],
      },
      {
        Header: " ",
        columns: [
          {
            Header: " ",
            accessor: "",
            Cell: ({ cell: { row: original } }) => {
              return (
                <button
                  type="button"
                  className="btn btn-info btn-sm mr-2"
                  onClick={() => {
                    history.push(
                      `/search/university/edit/${original.original.id}`
                    );
                  }}
                >
                  Edit
                </button>
              );
            },
          },
          {
            Header: "  ",
            accessor: "",
            Cell: ({ cell: { row: original } }) => {
              return (
                <button
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={() => {
                    handleShow();
                    setSelectedId(original.original.id);
                    setSelectedUniversity(original.original.university);
                  }}
                >
                  Delete
                </button>
              );
            },
          },
        ],
      },
    ],
    []
  );

  const university_data = React.useMemo(() => data, [data]);
  console.log(university_data);
  return (
    <>
      <Table columns={columns} data={university_data} />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedUniversity}</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              deleteUniversity(selectedId);
              handleClose();
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default List;
