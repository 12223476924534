import React, { useState } from "react";
import { FirestoreCollection } from "react-firestore";
import addCountry from "../../actions/addCountry";
import deleteCountry from "../../actions/deleteCountry";
import Error from "../misc/Error";
import { Modal, Button } from "react-bootstrap";

export default function Country() {
  const [countryName, setCountryName] = useState("");

  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState("");
  const [isSchengen, setIsSchengen] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  console.log("isSchengen :", isSchengen);
  return (
    <FirestoreCollection
      path={"country"}
      // sort="_likeCount:desc"
    >
      {({ error, isLoading, data }) => {
        {
          /* console.log("data :", data); */
        }
        if (error) {
          return <Error error={error} />;
        }

        if (isLoading) {
          return (
            <div className="d-flex justify-content-center align-items-center ">
              <div className="spinner-grow" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          );
        }

        return (
          <div>
            <div className="page-header mt-2">
              <div className="float-left">
                {data.length === 0 ? (
                  <h5 className="card-title">No country found</h5>
                ) : (
                  <h5 className="card-title">List of Countries</h5>
                )}
              </div>
              <div className="float-right">
                <button
                  type="button"
                  className="btn btn-primary"
                  data-toggle="modal"
                  data-target="#addCountry"
                  onClick={() => {
                    setCountryName("");
                    setIsSchengen(false);
                  }}
                >
                  Add Country
                </button>
              </div>
              <div className="clearfix"></div>
            </div>

            {data.length !== 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">#</th>
                    <th scope="col">Country</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((country, index) => (
                    <tr key={country.id}>
                      <th scope="row">{index + 1}</th>
                      <td>{country.country_name}</td>
                      <td>
                        <button
                          type="button"
                          className="btn btn-danger btn-sm"
                          onClick={() => {
                            handleShow();
                            setSelectedId(country.id);
                          }}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : null}

            <div
              className="modal fade"
              id="addCountry"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header border-0">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <div className="mb-3">
                      <label
                        htmlFor="exampleFormControlInput1"
                        className="form-label"
                      ></label>
                      <input
                        value={countryName}
                        onChange={(event) => setCountryName(event.target.value)}
                        type="text"
                        className="form-control"
                        id="exampleFormControlInput1"
                        placeholder="Country Name"
                        required
                        // onKeyDown={(e) => {
                        //   if (e.key === "Enter") {
                        //     if (!countryName) {
                        //       return false;
                        //     }
                        //     addCountry(countryName);
                        //     setCountryName("");
                        //   }
                        // }}
                      />
                    </div>
                    <div className="form-group col-md-6">
                      <label htmlFor="schengenArea">
                        Schengen Area &ensp;
                        <input
                          type="checkbox"
                          id="schengenArea"
                          name="schengenArea"
                          checked={isSchengen}
                          onChange={(e) => {
                            setIsSchengen(!isSchengen);
                          }}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="modal-footer border-0">
                    <button
                      type="button"
                      className="btn btn-primary"
                      data-dismiss="modal"
                      onClick={() => {
                        if (!countryName) {
                          return false;
                        }
                        addCountry(countryName, isSchengen);
                      }}
                    >
                      Add
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                <Modal.Title>Warning!</Modal.Title>
              </Modal.Header>
              <Modal.Body>Are you sure you want to delete?</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
                <Button
                  variant="primary"
                  onClick={() => {
                    deleteCountry(selectedId);
                    handleClose();
                  }}
                >
                  Yes
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        );
      }}
    </FirestoreCollection>
  );
}
