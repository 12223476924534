import Firebase from "firebase/app";
import ReactGA from "react-ga";
import { prepareDocForUpdate } from "./helpers/firestoreHelpers";

const updateCollege = (universityId, values) => {
  ReactGA.event({
    category: "Post",
    action: "Update post",
  });

  return Firebase.firestore()
    .collection("university")
    .doc(universityId)
    .update(prepareDocForUpdate(values))
    .catch((error) => {
      alert(`Whoops, couldn't edit the post: ${error.message}`);
    });
};

export default updateCollege;
