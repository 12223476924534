import React from "react";

import logIn from "../../actions/logIn";
import FirebaseAuth from "../misc/FirebaseAuth";
import Error from "../misc/Error";
import Profile from "./Profile";
import Subscription from "./Subscription";
import { Page } from "../../styles/layout";

const Account = () => (
  <Page>
    <FirebaseAuth>
      {({ isLoading, error, auth }) => {
        if (isLoading) {
          return null;
        }

        if (error) {
          return <Error error={error} />;
        }

        return (
          <div>
            <Profile auth={auth} />
          </div>
        );
      }}
    </FirebaseAuth>
  </Page>
);

export default Account;
