import React from "react";
import addCollege from "../../actions/addCollege";
import { useHistory } from "react-router-dom";
import { FirestoreCollection, FirestoreDocument } from "react-firestore";
import Error from "../misc/Error";
import updateCollege from "../../actions/updateCollege";

export default function EditCollege({ match }) {
  const history = useHistory();
  const { id } = match.params;
  const onSave = (event) => {
    event.preventDefault();

    const {
      university,
      location,
      foundation,
      academicQualification,
      interview,
      moi,
      inner,
      paymentSchedule,
      minRequiredDocuments,
      scholarship,
      applicationMode,
      boardAcceptance,
      turnAroundPeriod,
      yearGap,
      stepProcedure,
      remarks,
      currency,
      // fee,
      duration,
      feeUG,
      feePG,
      why,
      expenses,
    } = event.target.elements;

    const values = {
      university: university.value,
      location: location.value,
      foundation: foundation.value,
      academicQualification: academicQualification.value,
      interview: interview.value,
      moi: moi.value,
      inner: inner.value,
      paymentSchedule: paymentSchedule.value,
      minRequiredDocuments: minRequiredDocuments.value,
      scholarship: scholarship.value,
      applicationMode: applicationMode.value,
      boardAcceptance: boardAcceptance.value,
      turnAroundPeriod: turnAroundPeriod.value,
      yearGap: yearGap.value,
      stepProcedure: stepProcedure.value,
      remarks: remarks.value,
      currency: currency.value,
      // fee: fee.value,
      duration: duration.value,
      feePG: feePG.value,
      feeUG: feeUG.value,
      why: why.value,
      expenses: expenses.value,
    };

    console.log("EDIT :", values);

    updateCollege(id, values);
    history.goBack();
  };

  return (
    <FirestoreDocument path={`university/${id}`}>
      {({ error, isLoading, data }) => {
        {
          /* console.log(error);
        console.log(isLoading);
        console.log(data); */
        }
        if (error) {
          return <Error error={error} />;
        }

        if (isLoading) {
          return <p>loading...</p>;
        }

        if (data.length === 0) {
          return <Error />;
        }

        const university = data;
        {
          /* console.log(university); */
        }

        return (
          <form className="mt-2" onSubmit={onSave}>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="university">University</label>
                <input
                  type="text"
                  className="form-control"
                  name="university"
                  name="university"
                  defaultValue={university.university}
                  placeholder="University"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="location">Location</label>
                <input
                  type="text"
                  className="form-control"
                  name="location"
                  defaultValue={university.location}
                  placeholder="Location"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="foundation">Foundation / Pre Masters</label>
                <textarea
                  className="form-control"
                  name="foundation"
                  defaultValue={university.foundation}
                  placeholder="FOUNDATION / PRE MASTERS"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="academicQualification">
                  Academic Qualification
                </label>
                <textarea
                  className="form-control"
                  name="academicQualification"
                  defaultValue={university.academicQualification}
                  placeholder="Academic Qualification"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="interview">Interview</label>
                <input
                  type="text"
                  className="form-control"
                  name="interview"
                  defaultValue={university.interview}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="moi">MOI</label>
                <select
                  defaultValue={university.moi}
                  name="moi"
                  className="form-control"
                >
                  <option>Choose...</option>
                  <option>YES</option>
                  <option>NO</option>
                </select>
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="inner">Inner / Outer</label>
                <input
                  type="text"
                  className="form-control"
                  name="inner"
                  defaultValue={university.inner}
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="paymentSchedule">Payment Schedule</label>
                <textarea
                  className="form-control"
                  name="paymentSchedule"
                  defaultValue={university.paymentSchedule}
                  placeholder="Payment Schedule"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="minRequiredDocuments">
                  Min Required Documents
                </label>
                <textarea
                  className="form-control"
                  name="minRequiredDocuments"
                  defaultValue={university.minRequiredDocuments}
                  placeholder="Min Required Documents"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="scholarship">Scholarship</label>
                <input
                  type="text"
                  className="form-control"
                  name="scholarship"
                  defaultValue={university.scholarship}
                  placeholder="Scholarship"
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="applicationMode">Application Mode</label>
                <input
                  type="text"
                  className="form-control"
                  name="applicationMode"
                  defaultValue={university.applicationMode}
                  placeholder="Application Mode"
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-6">
                <label htmlFor="boardAcceptance">
                  Punjab , Gujarat & Hariyana Board Acceptance
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="boardAcceptance"
                  defaultValue={university.boardAcceptance}
                />
              </div>
              <div className="form-group col-md-6">
                <label htmlFor="turnAroundPeriod">
                  Offer Turn Around Period
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="turnAroundPeriod"
                  defaultValue={university.turnAroundPeriod}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="yearGap">Educational Year Gap (COMMON)</label>
                <textarea
                  className="form-control"
                  name="yearGap"
                  defaultValue={university.yearGap}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="stepProcedure">Step by Step Procedure</label>
                <textarea
                  className="form-control"
                  name="stepProcedure"
                  defaultValue={university.stepProcedure}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="remarks">Remarks</label>
                <textarea
                  className="form-control"
                  name="remarks"
                  defaultValue={university.remarks}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-3">
                <label htmlFor="duration">Course Duration</label>
                <input
                  type="text"
                  className="form-control"
                  name="duration"
                  defaultValue={university.duration}
                />
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="currency">Currency</label>
                <select
                  name="currency"
                  className="form-control"
                  defaultValue={university.currency}
                >
                  <option value="$">Dollar</option>
                  <option value="£">Pound Sterling</option>
                  <option value="€">Euro</option>
                  <option value="Fr">Swiss Franc</option>
                  <option value="₽">Russian Ruble</option>
                </select>
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="feeUG">Fee for UG</label>
                <input
                  type="text"
                  className="form-control"
                  name="feeUG"
                  defaultValue={university.feeUG}
                />
              </div>
              <div className="form-group col-md-2">
                <label htmlFor="feeUG">Fee for PG</label>
                <input
                  type="text"
                  className="form-control"
                  name="feePG"
                  defaultValue={university.feePG}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-4">
                <label htmlFor="why">Why this Country?</label>
                <textarea
                  className="form-control"
                  name="why"
                  defaultValue={university.why}
                />
              </div>
              <div className="form-group col-md-4">
                <label htmlFor="expenses">Expenses</label>
                <textarea
                  className="form-control"
                  name="expenses"
                  defaultValue={university.expenses}
                />
              </div>
            </div>

            <button type="submit" className="btn btn-primary">
              Update
            </button>
          </form>
        );
      }}
    </FirestoreDocument>
  );
}
