import Firebase from "firebase/app";
import ReactGA from "react-ga";

const deleteCourse = (id) => {
  ReactGA.event({
    category: "Post",
    action: "Delete post",
  });

  return Firebase.firestore()
    .collection("course")
    .doc(id)
    .delete()
    .catch((error) => {
      alert(`Whoops, couldn't delete the post: ${error.message}`);
    });
};

export default deleteCourse;
