import React from "react";
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
} from "react-table";
const classNames = require("classnames");

function Table({ columns, data, onAddChapter }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useGlobalFilter,
    usePagination
  );
  const onChange = useAsyncDebounce((value) => {
    console.log(value);
    setGlobalFilter(value || undefined);
  }, 200);
  // Render the UI for your table
  return (
    <>
      {/* <form className="form-inline my-2 my-lg-0"> */}
      <div className="row mb-2">
        <div className="col-3">
          <input
            className="form-control"
            type="search"
            placeholder="Search"
            aria-label="Search"
            onChange={(e) => {
              onChange(e.target.value);
            }}
          />
        </div>
        <div className="col-9">
          <div className="float-right">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => {
                onAddChapter();
                // history.push(
                //   `/search/university/${selectedCountry}/${selectedCourse}`
                // );
              }}
            >
              Add Chapter
            </button>
          </div>
        </div>
      </div>

      {/* </form> */}

      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>{column.render("Header")}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="row">
        <div className="row col-12">
          <div className="col">
            <ul className="pagination">
              <li
                className={classNames("page-item", {
                  disabled: !canPreviousPage,
                })}
                onClick={() => gotoPage(0)}
                // disabled={!canPreviousPage}
              >
                <a className="page-link" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
                  <span className="sr-only">Previous</span>
                </a>
              </li>
              <li
                className={classNames("page-item", {
                  disabled: !canPreviousPage,
                })}
                onClick={() => previousPage()}
                // disabled={!canPreviousPage}
              >
                <a className="page-link">Previous</a>
              </li>
              <li
                className={classNames("page-item", {
                  disabled: !canNextPage,
                })}
                // className="page-item"
                onClick={() => nextPage()}
                // disabled={!canNextPage}
              >
                <a className="page-link">Next</a>
              </li>
              <li
                className={classNames("page-item", {
                  disabled: !canNextPage,
                })}
                // className="page-item"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                <a className="page-link" aria-label="Next">
                  <span aria-hidden="true">&raquo;</span>
                  <span className="sr-only">Next</span>
                </a>
              </li>
            </ul>
          </div>
          <div className="col">
            <span>
              Page
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <span>
              {" "}
              | Go to page{" "}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  gotoPage(page);
                }}
                style={{ width: "100px" }}
              />
            </span>
          </div>
          <div className="col">
            <select
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[10, 20, 30, 40, 50].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </>
  );
}
export default Table;
